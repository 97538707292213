import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, BehaviorSubject, ReplaySubject, from, of, throwError } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { IRole } from 'src/deprecated/models-old/role/role.model';
import { IPartner } from '../../modules/partner/models/partner.model';

@Injectable({ providedIn: 'root' })
export class AuthServiceOld {
  // Use Auth0's Angular SDK observables
  isAuthenticated$ = this.auth0.isAuthenticated$.pipe(
    tap((loggedIn) => {
      this.loggedIn = loggedIn;
      if (loggedIn) {
        this.getRoles();
        this.getPartnerProfile();
      }
    }),
  );

  // Expose user profile from Auth0 SDK
  userProfile$ = this.auth0.user$;
  private userProfileSubject$ = new BehaviorSubject<any>(null);

  // Maintain existing subjects
  private apiUrl = environment.apiUrl + 'Authenticate';
  private Roles = new ReplaySubject<IRole[]>(1);
  public Partner = new ReplaySubject<IPartner>(1);
  loggedIn: boolean = null;

  constructor(
    private auth0: AuthService,
    private router: Router,
    private http: HttpClient,
  ) {
    // Mirror Auth0 user to legacy BehaviorSubject
    this.auth0.user$.subscribe((user) => this.userProfileSubject$.next(user));
  }

  // Existing methods with Auth0 SDK implementation
  getUser$(options?: any): Observable<any> {
    return this.auth0.user$;
  }

  localAuthSetup() {
    this.isAuthenticated$.pipe(concatMap((loggedIn: boolean) => (loggedIn ? this.getUser$() : of(loggedIn)))).subscribe((response) => {
      this.loggedIn = !!response;
    });
  }

  login(redirectPath = '/') {
    this.auth0.loginWithRedirect({
      appState: { target: redirectPath },
      authorizationParams: environment.auth.authorizationParams,
    });
  }

  handleAuthCallback() {
    this.auth0
      .handleRedirectCallback()
      .pipe(
        tap((cbRes) => {
          const targetRoute = cbRes.appState?.target || '/';
          this.router.navigate([targetRoute]);
        }),
      )
      .subscribe();
  }

  logout() {
    const protocol = location.hostname === 'localhost' ? 'http' : 'https';
    const returnTo = `${protocol}://${location.host}`;

    this.auth0.logout({
      logoutParams: {
        returnTo: returnTo,
        federated: true,
        clientId: environment.auth.clientId,
      },
    });
  }

  public getTokenSilently$(options?: any): Observable<any> {
    return from(this.auth0.getAccessTokenSilently(options)).pipe(
      catchError((err) => {
        if (err.error === 'login_required' || err.error === 'interaction_required' || err.error === 'invalid_grant') {
          this.login();
          return throwError(() => err);
        }
        return throwError(() => err);
      }),
    );
  }

  // Maintain existing permission and role functionality
  public hasPermission(inputNode: PermissionNode): boolean {
    let result = false;
    this.Roles.subscribe((roles) => {
      roles.forEach((item) => {
        if (item.permissions.includes(inputNode)) {
          result = true;
        }
      });
    });
    return result;
  }

  public hasPermissionObservable(inputNode: PermissionNode): Observable<boolean> {
    return this.Roles.pipe(map((roles) => roles.some((item) => item.permissions.includes(inputNode))));
  }

  public hasAcceptedCookiesObservable() {
    return this.getTokenSilently$().pipe(
      switchMap((token) => {
        const tokenData = JSON.parse(atob(token.split('.')[1]));
        const analyticsConsent = tokenData['user_metadata']?.cookie_policies?.analytics;
        return of(analyticsConsent === true || analyticsConsent === 'true');
      }),
    );
  }

  private getRoles() {
    this.http.get<IRole[]>(this.apiUrl + '/Roles').subscribe((data) => this.Roles.next(data));
  }

  private getPartnerProfile() {
    this.http.get<IPartner>(environment.partnerapiUrl + 'Partners/Self').subscribe((data) => this.Partner.next(data));
  }

  // Maintain legacy handleRedirectCallback$ observable
  handleRedirectCallback$ = from(this.auth0.handleRedirectCallback());
}

// Maintain existing PermissionNode enum
export enum PermissionNode {
  isAdmin = 0,
  isPartner = 1,
  developer = 2,
  partnerManageCustomCategories = 3,
  partnerManageCustomProducts = 4,
  partnerManageOwnLogins = 5,
  manageMicrosoftSeats = 6,
  superAdmin = 7,
  betaPage = 8,
  customPdfTemplate = 9,
  canResetMFA = 10,
  endCustomerAccounts = 11,
  dropboxManagement = 12,
  infrastructureManagement = 13,
  acronisManagement = 14,
  setCustomerAsHighRisk = 15,
  enableNceConvertionTool = 16,
  ESETManagement = 17,
  HelloSignManagement = 18,
  ExclaimerManagement = 25,
  SPLAManagement = 19,
  KeepItManagement = 20,
  MicrosoftReportRenewals = 21,
  microsoftRobotAccess = 22,
  PowerBIReports = 23,
  BillingData = 24,
  RelocatorPilot = 26,
  ActivityLog = 27,
  LegacyLog = 28,
  MicrosoftTransferRead = 29,
  MicrosoftTransferWrite = 30,
  MicrosoftSubscriptionsRead = 31,
  MicrosoftSubscriptionsCreate = 32,
  MicrosoftSubscriptionsUpdate = 33,
  MicrosoftSubscriptionsUpgrading = 34,
  AzurePlanRead = 35,
  AzurePlanCreate = 36,
  AzurePlanUpdate = 37,
  AzurePlanRoleManagement = 38,
  ImpossibleCloudManagement = 39,
  TwingateManagement = 40,
  MicrosoftTenantRead = 41,
  MicrosoftTenantCreate = 42,
  MicrosoftTenantUpdate = 43,
  MicrosoftTenantDelete = 44,
  MicrosoftCustomerMigratorRead = 45,
  MicrosoftCustomerMigratorWrite = 46,
  ContactPointsRead = 47,
  ContactPointsWrite = 48,
}
